(function($) {

  $(document).on('turbolinks:load', function() {
    legacy_ready();
  })

  // $(document).ready(function() {
  //   legacy_ready();
  // });
  //
  // $(window).load(function() {
  //   legacy_load();
  // });

  function legacy_load() {

    //   /* isotop */
    //   if ($('#search .filter').length > 0) {
    //
    //     jQuery('.isotope').isotope({
    //       itemSelector: '.item',
    //       layoutMode: 'fitRows',
    //     })
    //
    //     jQuery('#search .filter select').change(function() {
    //       updateFilter();
    //     });
    //   }
    //
  }

  function legacy_ready() {
    /* get gr path */
    var pathname = window.location.pathname;

    if (pathname.search("/gr/") >= 0 || pathname.search("/gr-en/") >= 0) {
      var greekLink = "http://margaritis-trucks.gr/el/vehicles-2.html";
      $('#main-navigation ul.level1 li:nth-child(2) a').attr('href', greekLink).attr('target', '_blank');
    }

    /* vehicle downloads remove .pdf */
    if ($('.vehicle-downloads').length > 0) {
      $('.vehicle-downloads a').each(function() {
        var pdfName = $(this).html().slice(0, -4);
        $(this).html(pdfName);
      });
    }

    /* vehicle form */
    if ($('.vehicle-information .vehicle-id span').length > 0) {
      var theID = $('.vehicle-information .vehicle-id span').html();
      $('form#contact-form input#vehicleID').attr('value', theID);
    }

    /* mobile navigation */
    $('#mobile-nav-trigger').click(function() {
      if ($('#main-navigation').hasClass('isOpen')) {
        $('#main-navigation').slideUp().removeClass('isOpen');
      } else {
        $('#main-navigation').slideDown().addClass('isOpen');
      }
    });

    /* mobile cat navigation */
    $('#mobile-nav-trigger-vehicles').click(function() {
      if ($('.cat-navigation').hasClass('isOpen')) {
        $('.cat-navigation').slideUp().removeClass('isOpen');
      } else {
        $('.cat-navigation').slideDown().addClass('isOpen');
      }
    });

    /* royal slider */
    setTimeout(() => {
      if ($('#royal').length > 0) {
        $('#royal img').unwrap().unwrap().unwrap();
        $('#royal .csc-textpic-imagewrap').unwrap().unwrap().removeClass('csc-textpic-imagewrap').addClass('royalSlider');
        //$('#royal .csc-textpic-text').addClass('royalText').removeClass('csc-textpic-text').wrapInner('<div class="col-sm-6 col-sm-offset-3 royalTextInner" />').wrapInner('<div class="row" />').wrapInner('<div class="container" />');
        $('#royal .rsContent h1').unwrap();
        $('#royal .royalSlider').royalSlider({
          arrowsNav: false,
          loop: true,
          keyboardNavEnabled: false,
          imageAlignCenter: true,
          controlsInside: false,
          imageScaleMode: 'fill',
          slidesSpacing: 0,
          arrowsNavAutoHide: false,
          autoScaleSlider: true,
          autoScaleSliderWidth: 1600,
          autoScaleSliderHeight: 500,
          controlNavigation: 'none',
          thumbsFitInViewport: true,
          navigateByClick: false,
          startSlideId: 0,
          autoPlay: {
            enabled: true,
            pauseOnHover: false,
            delay: 8000
          },
          transitionType: 'fade',
          globalCaption: false,
          deeplinking: {
            enabled: true,
            change: false
          },
          imgWidth: 1600,
          imgHeight: 500,

          thumbs: {
            spacing: 0,
            arrowsAutoHide: true,
            arrows: false,
            autoCenter: false,
            firstMargin: false,
            fitInViewport: false
          }
        });
      }
    }, 50);
    /* bxslider */
    if ($('.slick').length > 0) {
      $('.slick').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    }

    /* fancybox */
    if ($('#vehicle-slide-show').length > 0) {

      $('#vehicle-slide-show .slick-slide').each(function() {
        var img = $(this).find('img');
        var imgSrc = img.attr('src');
        img.wrap('<a href= "' + imgSrc + '" class="fancybox" rel="group">');
      });

      $('.fancybox').fancybox();

    }

    /* slide to top */
    $("a[href='#top']").click(function() {
      $("html, body").animate({
        scrollTop: 0
      }, "slow");
      return false;
    });
    if ($(window).width() < 992) {
      $('.arrow-to-top').fadeOut(0);
    }
    $(window).scroll(function() {
      if ($(window).width() < 992) {
        if ($(this).scrollTop() > 500) {
          $('.arrow-to-top').fadeIn();
        } else {
          $('.arrow-to-top').fadeOut();
        }
      }
    });
  }

  function updateFilter() {
    var filterValue = '';
    jQuery('#search .filter select').each(function() {

      var tmpValue = '.' + $("option:selected", this).val()
      if (tmpValue != '.') {
        filterValue += tmpValue;
      }
    });

    if (filterValue == '') {
      filterValue = '*';
    }

    var count = jQuery('.isotope > ' + filterValue).length;

    jQuery('.vehicles .count strong').html(count);

    jQuery('.isotope').isotope({
      filter: filterValue
    });
  }
})(jQuery);
